@import url('@fortawesome/fontawesome-free/css/all.css');

/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color:  #f3f4f6;
  color:#FF9500;
}

/* .App {
  text-align: center;
} */

/* Mobile and Tablet Adjustments */
@media screen and (max-width: 768px) {
  .portfolio-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .portfolio-grid {
    grid-template-columns: 1fr;
  }
}

/* Extra Small Screen (Mobile) */
@media screen and (max-width: 480px) {
  .hero h1 {
    font-size: 1.6rem;
  }

  .hero p {
    font-size: 0.9rem;
  }

  .get-started-btn {
    padding: 0.5rem 1.2rem;
    font-size: 0.8rem;
  }

  .features h2 {
    font-size: 1.6rem;
  }

  .stat-item {
    font-size: 1rem;
  }

  .feature-card {
    width: 80%;
  }

  .pricing-option {
    width: 80%;
  }

  .testimonial {
    width: 80%;
  }
}

/* Global Styles */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f6f9;
  color: #333;
}

/* Section Title */
.section-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: bold;
  color: #2c3e50;
  text-align: center;
  border-bottom: 2px solid #3498db;
  display: inline-block;
  padding-bottom: 8px;
}

/* Typography for Headings and Paragraphs */
h2 {
  font-size: 1.8rem;
  margin-top: 30px;
  margin-bottom: 15px;
  font-weight: 600;
  color: #34495e;
  /* border-left: 4px solid #3498db; */
  padding-left: 12px;
}

p, address {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

/* Ordered List Styling */
ol {
  margin: 10px 0;
  padding-left: 20px;
  list-style-position: inside; /* Aligns numbers closer to content */
  font-size: 1rem;
  color: #555;
}

li {
  margin-bottom: 10px; /* Adds space between list items */
}

/* Link Styles */
a {
  color: #3498db;
  text-decoration: none;
  font-weight: 500;
}

a:hover {
  text-decoration: underline;
}

/* Address Styles */
address {
  font-style: normal;
  background-color: #ecf0f1;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .content-wrapper {
    padding: 20px;
  }

  .section-title {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p, address, ol {
    font-size: 0.9rem;
  }
}
