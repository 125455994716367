.hero {
  background-color: #e0f4ff;
  padding: 5rem 2rem;
  color: #333;
}

.hero h1 {
  font-size: 2.5rem;
  color: #FF9500;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.get-started-btn {
  background-color: #FF9500;
  color: #fff;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 15px;
}
/* Ensure all images have the same size */
.slide-image {
  width: 100%;
  height: 500px; /* Set a uniform height */
  object-fit: cover; /* Maintains aspect ratio, covering the area */
}

@media screen and (max-width: 768px) {
  .hero {
    padding: 3rem 1rem;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .get-started-btn {
    font-size: 0.9rem;
    padding: 0.6rem 1.5rem;
  }
}