.case-studies-section {
  padding: 4rem 2rem;
  background-color: #f3f4f6;
}

.case-studies-section h1 {
  font-size: 2.5rem;
  color: #FF9500;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: bold;
}

.case-study-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
  padding: 2rem;
  margin-bottom: 2rem;
}

.case-study-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.case-study-item h2 {
  font-size: 1.8rem;
  color: #FF9500;
  margin-bottom: 1rem;
}

.case-study-item p {
  font-size: 1rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.read-more-btn {
  background-color: #FF9500;
  color: #fff;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.read-more-btn:hover {
  background-color: #FFD580
  ;
}

.case-studies-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.case-studies-grid .case-study-item:nth-child(4),
.case-studies-grid .case-study-item:nth-child(5) {
  grid-column: span 1;
}