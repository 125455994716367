.features {
  padding: 4rem 2rem;
  background-color: #fff;
  text-align: center;
}
.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.barber-image {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  object-fit: cover;
  margin-bottom: 30px;
}

.features h2 {
  font-size: 2rem;
  color:#FF9500;
  margin-bottom: 1rem;
}

.features p {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.stats {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 3rem;
}

.stat-item {
  font-size: 1.2rem;
  font-weight: bold;
  color: #FF9500;
}

.feature-cards {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.feature-card {
  background-color: #f0f4ff;
  padding: 2rem;
  border-radius: 10px;
  width: 30%;
}

.feature-card h3 {
  color: #FF9500;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .features {
    padding: 3rem 1rem;
  }

  .feature-cards {
    flex-direction: column;
  }

  .feature-card {
    width: 80%;
    margin: 0 auto 1.5rem;
  }

  .stats {
    flex-direction: column;
    gap: 1rem;
  }
}