.tabs-container {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.tab-button.active {
  color: #ffa726;
  font-weight: bold;
}

.tab-indicator {
  position: absolute;
  bottom: 0;
  height: 4px;
  background-color: #ffa726;
  transition: all 0.3s ease;
  z-index: 0;
}

.booking-list {
  display: flex;
  /* flex-direction: column; */
  gap: 20px;
  margin: 2%;
}

.booking-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.booking-card:hover {
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  transform: scale(1.005);
}

.studio-name {
  font-weight: bold;
  font-size: 18px;
}

.location,
.total-amount {
  color: #757575;
}

.total-amount {
  font-weight: bold;
  color: #ffa726;
}

@media (min-width: 1400px) { 
  .booking-card {
    width: 32%;
  }
}

@media (max-width: 1399px) and (min-width: 922px) {
  .booking-card {
    width: 48%;
  }
}

@media (max-width: 921px) and (min-width: 721px) {
  .booking-card {
    /* width: 100%; */
  }
}
