/* Desktop view (default) */
.header-h {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #ff9500;
}

.header-h .logo {
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
}

.nav-h {
  display: flex;
  gap: 3rem; /* Adding space between links */
}

.nav-h a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}

.nav-h a:hover {
  color: #ffffff; 
  text-decoration: none; 
  transform: translateY(-3px); 
  transition: all 0.3s ease; 
}

.auth-buttons-h button {
  padding: 0.5rem 1rem;
  border: none;
  color: #555;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.auth-buttons-h .sign-up {
  background-color: #ff9500;
  color: #fff;
}

/* Mobile view (toggle icon) */
@media screen and (max-width: 940px) {
  .header-h {
    /* flex-direction: column; */
    position: relative;
  }

  .header-h a {
    margin-left: 10%;
  }

  .nav-h {
    display: none; /* Hide menu by default */
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #ff9500;
    padding: 1rem;
    gap: 1rem;
    position: absolute;
    top: 60px; /* Position the menu below the header */
    left: 0;
    margin-top: -10%;
  }

  .nav-h.open {
    display: flex; /* Show menu when it's open */
  }

  .menu-toggle-h {
    display: block; /* Ensure it is visible on mobile */
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 30%;
    left: 1rem;
    z-index: 10; /* Keep it above other elements */
  }
  
  .menu-toggle-h .bar-h {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px 0;
    background-color: #fff; /* White color for visibility */
    transition: all 0.3s ease;
  }
  
  /* Add animation for toggle effect */
  .menu-toggle-h.open .bar-h:nth-child(1) {
    transform: rotate(45deg) translateY(8px);
  }
  
  .menu-toggle-h.open .bar-h:nth-child(2) {
    opacity: 0;
  }
  
  .menu-toggle-h.open .bar-h:nth-child(3) {
    transform: rotate(-45deg) translateY(-8px);
  }  

  .auth-buttons-h {
    margin-top: 1rem;
  }
}

/* Hide the hamburger icon in desktop view */
@media screen and (min-width: 940px) {
  .menu-toggle-h {
    display: none; /* Hide the hamburger menu in desktop view */
  }
}
