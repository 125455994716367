.testimonials {
  padding: 4rem 2rem;
  background-color: #fff;
}

.testimonials h2 {
  color: #FF9500;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 2rem;
}

.testimonial {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 10px;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.testimonial p {
  margin-bottom: 1rem;
  color: #333;
  font-size: 1.1rem;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .testimonials {
    padding: 3rem 1rem;
  }

  .testimonial {
    width: 90%;
  }
}