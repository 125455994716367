.service-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 10px;
  overflow: hidden;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  text-transform: capitalize;
}

.card-body {
  padding: 2%;
}

.text-primary {
  font-size: 1rem;
  font-weight: 600;
  color: rgb(255, 149, 0) !important; 
}

.btn {
  border-radius: 5px;
}

.btn-primary {
  background-color: rgb(255, 149, 0);
  border-color: rgb(255, 149, 0);
}

.btn-primary:hover {
  background-color: rgb(223, 133, 7);
  border-color: rgb(255, 149, 0);
}

.btn-danger {
  background-color: #f1737f;
  border-color: #f1737f;
}

.btn-danger:hover {
  background-color: #a71d2a;
  border-color: #7a0c1e;
}

/* Content Area */
.content-container {
  margin-left: 0;
  transition: margin-left 0.3s ease;
}

@media (min-width: 768px) {
  .content-container {
    margin-left: 250px;
  }
}

.content-container.with-sidebar {
  margin-left: 250px;
}

@media (max-width: 767px) {
  .content-container {
    margin-left: 0;
  }
}
