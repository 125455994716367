.privacy-policy-container-p {
  padding: 40px;
  font-family: "Roboto", Arial, sans-serif;
  /* background-color: #ffffff; */
  color: #2c3e50;
  line-height: 1.8;
}

.content-wrapper-p {
  max-width: 1600px; /* Default for desktop */
  margin: 40px auto;
  padding: 30px;
  /* background-color: #f9f9f9; */
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  /* border-radius: 10px; */
}

/* For mobile and smaller screens */
@media (max-width: 768px) {
  .content-wrapper-p {
    max-width: 1200px; /* Max width for mobile view */
  }
}

.section-title-p {
  font-size: 2.6em;
  color: #34495e;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.effective-date-p {
  font-size: 1.2em;
  color: #525959;
  text-align: center;
  margin-bottom: 30px;
}

.title-p {
  font-size: 2em;
  color: #2c3e50;
  margin-top: 30px;
  font-weight: 600;
  padding-left: 15px;
}

.subtitle-p {
  font-size: 1.4em;
  color: #34495e;
  margin-top: 20px;
  font-weight: 500;
}

.text-p {
  text-align: left;
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #555;
}

/* p, */
/* ol, */
.list-p {
  font-size: 1.1em;
  line-height: 1.8;
  /* margin-bottom: 20px; */
}

/* ol {
  list-style-type: decimal;
  padding-left: 30px;
} */

.ul-list-p {
  list-style-type: disc;
  padding-left: 30px;
}

.list-p {
  margin-bottom: 5px;
}

.link-p {
  color: #3498db;
  text-decoration: none;
  font-weight: 500;
}

.link-p:hover {
  text-decoration: underline;
}

.address-p {
  font-style: normal;
  margin-top: 20px;
  line-height: 1.6;
  color: #555;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .content-wrapper-p {
    padding: 20px;
  }

  .section-title-p {
    font-size: 2em;
  }

  .title-p {
    font-size: 1.8em;
  }

  .subtitle-p {
    font-size: 1.2em;
  }

  /* p, */
  /* ol, */
  .list-p {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .privacy-policy-container-p {
    padding: 20px;
  }

  .section-title-p {
    font-size: 1.8em;
  }

  .effective-date-p {
    font-size: 1em;
  }

  .title-p {
    font-size: 1.6em;
  }

  .subtitle-p {
    font-size: 1em;
  }

  /* p, */
  /* ol, */
  .list-p {
    font-size: 0.95em;
    /* margin-bottom: 20px; */
  }

  .ul-list-p {
    padding-left: 20px;
  }
}
