.main {
  margin-top: 2%;
  /* margin-left: 10%; */
  font-size: 28px;
  padding: 0 10px;
  width: 100%;
}

.main h2 {
  color: #333;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 24px;
  margin-bottom: 10px;
}

.main .card-profile-profile {
  background-color: #fff;
  border-radius: 18px;
  box-shadow: 1px 1px 8px 0 grey;
  height: auto;
  margin-bottom: 20px;
  /* padding: 20px 0 20px 50px; */
  padding-top: 10%;
  padding-bottom: 10%;
}

.main .card-profile-profile table {
  border: none;
  font-size: 16px;
  height: 270px;
  width: 80%;
}

.edit {
  position: absolute;
  color: #e7e7e8;
  right: 14%;
}

/* General styles for the profile card */
.card-profile-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto; 
}

.card-profile-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

/* Profile image styling */
.profile-image img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  /* margin-bottom: 20px;  */
}

.edit-icon {
  position: absolute;
  top: 11%;
  right: 10%;
}
 
@media (min-width: 768px) {
  .card-profile-profile {
    flex-direction: row; 
    align-items: flex-center; 
    justify-content: flex-center; 
    padding-left: 10%;
    padding-right: 10%;
  }

  .main .card-profile-profile {
    width: 85%;
  }
  .profile-image {
    /* margin-right: 20px; */
    /* margin-bottom: 0;  */
  }
  .profile-image img {
    width: 300px;
    height: 300px;
  }

  .edit-icon {
    position: absolute;
    top: 15%;
  }
}
