.modal-content {
  background-color: #f8f9fa; 
  border-radius: 10px; 
  border: 1px solid #ccc; 
}

.modal-header {
  background-color: rgb(255, 149, 0); 
  color: white; 
  font-weight: bold; 
  border-radius: 10px; 
}

.modal-title {
  font-size: 1.25rem; 
}

.modal-body {
  font-size: 1rem; 
  color: #333; 
}

.modal-footer {
  border-bottom-left-radius: 10px; 
  border-bottom-right-radius: 10px; 
  display: flex;
  justify-content: flex-end; 
}

button {
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.modal-backdrop.show {
  opacity: 0;
}