.dashboard-card {
  height: 270px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .dashboard-card {
    height: 150px;
    width: 100%;
  }
}
