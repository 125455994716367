.pricing {
  padding: 4rem 2rem;
  background-color: #e0f4ff;
}

.pricing h2 {
  color: #FF9500;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.pricing-options {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.pricing-option {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  width: 30%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pricing-option h3 {
  color: #FF9500;
  margin-bottom: 1rem;
}

.pricing-option .price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.pricing-option ul {
  list-style-type: none;
  padding: 0;
}

.pricing-option ul li {
  margin-bottom: 0.5rem;
}

.pricing-option button {
  background-color: #FF9500;
  color: #fff;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .pricing-options {
    flex-direction: column;
    gap: 1.5rem;
  }

  .pricing-option {
    width: 80%;
    margin: 0 auto;
  }
}