.sidebar-closed {
  transform: translateX(-100%);
}

.sidebar-open {
  transform: translateX(0);
}

.dropdown-menu .dropdown-item:focus {
  background-color: #ff9500; 
  color: #fff; 
  outline: none; 
  box-shadow: 0 0 0 2px rgba(255, 149, 0, 0.6);
}

@media (min-width: 768px) {
  /* .btn.btn-primary {
    display: none;
  } */

  .sidebar-closed {
    transform: translateX(0);
  }
}