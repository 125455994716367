.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  box-sizing: border-box;
}

.modal-content {
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  width: 100%;
  max-width: 420px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  position: relative;
  text-align: center;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #555;
  cursor: pointer;
  transition: color 0.2s ease;
}

.close-btn:hover {
  color: #ff4d4d;
}

form {
  width: 100%;
  margin-top: 20px;
}

form h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
}

form label {
  font-size: 0.9rem;
  color: #555;
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.2s ease;
}

form input:focus {
  border-color: #FF9500;
}

.submit-btn {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  background-color: #FF9500;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.submit-btn:hover {
  background-color: #fd9402d7;
  /* background-color: #0004ff; */
}

.toggle-link {
  font-size: 0.9rem;
  color: #FF9500;
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
  text-decoration: none;
  transition: color 0.2s ease;
}

.toggle-link:hover {
  color: #FF9500;
  text-decoration: underline;
}

/* Error Message Styles */
.error {
  font-size: 0.85rem;
  color: #ff4d4d;
  margin-top: -10px;
  margin-bottom: 10px;
  text-align: left;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .modal-content {
    width: 100%;
    padding: 20px;
  }

  form h2 {
    font-size: 1.5rem;
  }

  form input,
  .submit-btn {
    font-size: 0.95rem;
    padding: 10px;
  }

  .close-btn {
    font-size: 1.2rem;
  }
}
