.history-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.history-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* transform: scale(1.01); */
}

@media (min-width: 768px) {
  .history-card {
    width: 32%;
  }
}