.services-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: start;
}

.service-box {
  width: 150px;
  height: 100px;
  border: 2px solid #ccc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
}

.service-box:hover {
  border-color: rgb(255, 149, 0);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.service-box.selected {
  border-color: rgb(255, 149, 0);
  background-color: rgb(252, 179, 77);
}

.service-label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  pointer-events: none;
  width: 90%;
}

@media (max-width: 768px) {
  .service-box {
    width:105px; 
    height: 60px;
  }

  .services-container {
    justify-content: center;
  }

  .responsive-btn {
    width: 25%;
  }

  .custom-height {
    height: 40%;
  }

  textarea.form-control.custom-height {
    height: 100px;
  }

  .desktop-only {
    right: 7% !important;
  }
}

.responsive-btn {
  width: 40%;
}

.form-responsive {
  width: 100%;
}

button.btn:hover {
  background-color: rgb(252, 179, 77);
}

button.btn:active {
  background-color: rgb(252, 179, 77) !important;
  outline: none;
}

@media (min-width: 992px) { 
  .main-container {
    /* width: 50%; */
  }
  .responsive-btn {
    width: 20%;
  }
  .custom-height {
    height: 37px;
  }
  
  textarea.form-control.custom-height {
    min-height: auto;
    height: 37px;
  }
  
  input.form-control.custom-height {
    height: 37px;
  }

  .desktop-only {
    right: 4.2% !important;
  }
}
