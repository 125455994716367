.footer-f {
  background-color: #ff9500;
  color: #fff;
  padding: 2rem 0;
  text-align: center;
}

.footer-nav-f {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.footer-nav-f a {
  margin: 0 1rem;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
}

.footer-nav-f a:hover {
  color: #f4d03f;
}

.footer p {
  margin: 0;
  font-size: 0.9rem;
}

/* Responsive Footer */
@media screen and (max-width: 768px) {
  .footer {
    padding: 2rem 1rem;
  }

  .footer-nav-f {
    flex-direction: column;
    align-items: center;
  }

  .footer-nav-f a {
    margin: 0.5rem 0;
  }
}
